import { ApiTariffCallRequest } from 'ApiRest/Api/Account/Tariff/CallRequest'
import { ApiTariffList } from 'ApiRest/Api/Account/Tariff/List'

export default {
  async fetchTariffs({ commit }) {
    const { data } = await ApiTariffList.get()

    commit('setTariffs', data)
  },

  async callRequest({ state }) {
    const payload = {
      phone: state.phone,
      tariff: {
        id: state.educationTypeId,
      },
    }

    await ApiTariffCallRequest.put(payload)
  },
}
